footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 0.75rem;
}

footer p {
  margin-bottom: 0.25rem;
}
footer a {
  color: #999;
}